import React, { useEffect, useState } from 'react'
import { Loader } from 'semantic-ui-react'
import { size } from 'lodash'
import { posts } from '../../api'
import { useAuth } from '../../hooks'
import { PostItem } from '../PostItem'

const postsController = new posts()

export function ListForm(props) {
  const { postActive, reload, onReload, msgResult, isFind, searchPost } = props
  const [posts, setPosts] = useState(null);
  const { token } = useAuth()

  useEffect(() => {
    (async () => {
      try {

        if(isFind){
          setPosts(null)

          if(!searchPost){
            const response = await postsController.getAllPosts(token)
            return setPosts(response)
          }

          const response = await postsController.getAllPosts(token)
          const filter = response.filter(
            d => {
              let val = d.title.toUpperCase().indexOf(searchPost.toUpperCase()) >= 0
              let val2 = d.description.toUpperCase().indexOf(searchPost.toUpperCase()) >= 0
              let val3 = d.information.toUpperCase().indexOf(searchPost.toUpperCase()) >= 0
              let val4 = d.category.toUpperCase().indexOf(searchPost.toUpperCase()) >= 0
              let val5 = d.subcategory.toUpperCase().indexOf(searchPost.toUpperCase()) >= 0
    
              if(val){
                return val
              }
              if(val2){
                return val2
              }
              if(val3){
                return val3
              }
              if(val4){
                return val4
              }
              if(val5){
                return val5
              }
    
              return false
            }
          );
          setPosts(filter)
        } else {
          setPosts(null)
          const response = await postsController.getAllPosts(token)
          const filter = response.filter(d => d.activated === postActive)
          setPosts(filter)
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [postActive, reload, searchPost]);

  if(!posts) return <Loader active inline='centered' />
  if(size(posts) === 0) return 'No existen posts registrados'

  return posts.map(post => <PostItem key={post.id_post} post={post} onReload={onReload} msgResult={msgResult} />)
}
