import * as Yup from 'yup'

export function initialValues(post) {
  return{
    image: post?.image || '',
    fileImage: null,
    file: post?.file || '',
    filePost: null, 
    category: post?.category || '',
    subcategory: post?.subcategory || '',
    title: post?.title || '',
    description: post?.description || '',
    information: post?.information || '',
    info_view: post?.info_view || false
  }
}

export function validationSchema() {
  return Yup.object({
    category: Yup.string()
      .required(true),
    // subcategory: Yup.string()
    //   .required(true),
    title: Yup.string()
      .required(true),
    description: Yup.string()
      .required(true),
    // information: Yup.string()
    //   .required(true)
  })
}