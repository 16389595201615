import React, { useState } from 'react'
import { Image, Button, Icon, Confirm } from 'semantic-ui-react'
import { BasicModal } from '../Shared'
import { posts } from '../../api/'
import { useAuth } from '../../hooks'
import { PostForm } from '../PostForm'
import { SERVER_IP } from '../../utils'
import { Image as img } from '../../assets'
import './UserItem.scss'

const postsControlle = new posts()

export function PostItem({post, onReload, msgResult}) {
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [isDelete, setIsDelete] = useState(false);
  const { token, ...data} = useAuth()

  const onOpenCloseModal = () => setShowModal((prevState) => !prevState)
  const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState)
  
  // const validateUserActivate = () => {
  //   if()
    
  //   return false
  // }

  const openUpdatePost = () => {
    setTitleModal(`Actualizar: ${post.title}`)
    onOpenCloseModal()
  }

  const openDesactivateActivateConfim = () => {
    setIsDelete(false)
    setConfirmMessage(
      post.activated ? 
      `Desactivar post: ${post.title}` 
      : `Activar post: ${post.title}`
      )
    onOpenCloseConfirm()
  }

  const openDeleteConfim = () => {
    setIsDelete(true)
    setConfirmMessage(`Eliminar post: ${post.title}`)
    onOpenCloseConfirm()
  }

  const onActivateDesactivate = async () => {
    try {
     const response =  await postsControlle.putPostsAoD(token, {
         activated: !post.activated,
         id_user: post.id_user
        }, 
        post.id_post
      )
      onReload()
      onOpenCloseConfirm()
      msgResult('ok', response.msg)
    } catch (error) {
      msgResult('error', error.error)
    }
  }

  const onDeletePost = async () => {
    try {
     const response =  await postsControlle.deletePost(token, post.id_post)
      onReload()
      onOpenCloseConfirm()
      msgResult('ok', response.msg)
    } catch (error) {
      msgResult('error', error.error)
    }
  }
 
  return (
    <>
      <div className='post-item'>
        <div className='post-item__info'>
          <Image 
            src={
              post.image ? `${SERVER_IP}/${post.image}` : img.noAvatar
            }
          />
          <div>
            <p>
              {post.title}
            </p>
            <p>{post.category}</p>
          </div>
        </div>

        <div>
          <Button icon primary onClick={openUpdatePost}>
            <Icon name='pencil' />
          </Button>
          <Button icon color={post.activated ? 'orange' : 'teal'} onClick={openDesactivateActivateConfim} >
            <Icon name={post.activated ? 'ban' : 'check'} />
          </Button>
          <Button icon color='red' onClick={openDeleteConfim}>
            <Icon name='trash' />
          </Button>
        </div>
      </div>

      <BasicModal show={showModal} close={onOpenCloseModal} title={titleModal} >
        <PostForm 
          close={onOpenCloseModal}
          onReload={onReload}
          post={post}
          msgResult={msgResult}
        />
      </BasicModal>

      <Confirm
        open={showConfirm}
        onCancel={onOpenCloseConfirm}
        onConfirm={isDelete ? onDeletePost : onActivateDesactivate}
        content={confirmMessage}
        size='mini'
      />
    </>
  )
}
