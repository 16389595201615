import React, { useState } from 'react'
import { Button, Confirm, Icon, Item,  } from 'semantic-ui-react'
import { SubcatetoryForm } from '../SubcatetoryForm'
import { BasicModal } from '../Shared'
import { subcategory } from '../../api'
import { useAuth } from '../../hooks'
import './ListConfig.scss'

const controllerCategory = new subcategory()

export const ListSubCategory = (props) => {
  const { token } = useAuth()
  const [openNew, setOpenNew] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { action, showModal, onReload, data, msgResult} = props

  const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState)

  const editOpen = () => {
    setIsEdit(true)
    setOpenNew((prevState) => !prevState)
  }

  const deleteCategory = async () => {
    try {
      const response = await controllerCategory.deleteSubCategory(token, data.id_subcategory)
      onOpenCloseConfirm()
      onReload()
      msgResult('ok', response.msg)
    } catch (error) {
      msgResult('error', error.error)
    }
  }

  return (
    <>
      <Item.Group divided>
        <Item>
          <Item.Content  className='category'>
            <h5>{data.category_name || 'S/I' }</h5>         
          </Item.Content>
          <Item.Content  className='titleSubcategory'>
            <h5 className='text'>{data.text}</h5>         
          </Item.Content>
          <Button icon primary onClick={editOpen} className='iconEdit'>
            <Icon name='pencil' />
          </Button>

          <Button icon color='red' onClick={onOpenCloseConfirm}>
              <Icon name='trash' />
          </Button>
        </Item>
      </Item.Group>

      {
        isEdit ? (
          <BasicModal close={editOpen} show={openNew}  title='Editar Subcategoría'>
            <SubcatetoryForm close={editOpen} onReload={onReload} config={data} msgResult={msgResult} />
          </BasicModal>
        ) : (
          <BasicModal close={action} show={showModal}  title='Agregar Subcategoría'>
            <SubcatetoryForm close={action} onReload={onReload} msgResult={msgResult} />
          </BasicModal>
        )
      }

      <Confirm
        open={showConfirm}
        onCancel={onOpenCloseConfirm}
        onConfirm={deleteCategory}
        content={`¿Desea eliminar la subcategoría ${data.text}?`}
        size='mini'
      />
    </>
  )
}