import { SERVER_IP } from '../utils'

export class getUser {
  async getAllUser(token){
    try {
      const url = `${SERVER_IP}/api/user/`
      const params = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'xtkfeda': token
        }
      }

      const response = await fetch(url, params)
      const result = await response.json()
      if(response.status !== 200) throw result
      return result

    } catch (error) {
      throw error
    }
  }
}