import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { WebRouter } from './WebRouter'
import { AdminRouter } from './AdminRouter'
import { useAuth } from '../hooks/useAuth'

export function AppRouter() {
  const { token } = useAuth()

  return (
    <Routes>
      {
        token ? <Route path='/*' element={<WebRouter />} /> :
        <Route path='/admin/*' element={<AdminRouter />} />
      }
      <Route path='/*' element={<Navigate to='/admin' /> } />
    </Routes>
  )
}
