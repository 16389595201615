import React, { useEffect, useState } from 'react'
import { KpiCard } from '../../../components'
import { Loader } from 'semantic-ui-react'
import { Post, UserLogin, Users } from '../../../components/Kpi'
import { ValidateToken } from '../../../components/ValidateToken'
import { getUser, posts } from '../../../api'
import { useAuth } from '../../../hooks'
import './Home.scss'

const controllerUser = new getUser()
const controllerPost = new posts()

export function HomePage() {
  ValidateToken()
  const { token } = useAuth()
  const [useActivated, setUseActivated] = useState(null);
  const [useInactivated, setUseInactivated] = useState(null);
  const [postActivate, setPostActivate] = useState([]);
  const [postInactivated, setPostInactivated] = useState([]);
  const [loginForMonth, setloginForMonth] = useState([]);
  
  const getUsers = async() => {
    try {
      const user = await controllerUser.getAllUser(token)
      user.map(d => {
        setloginForMonth(loginForMonth => [...loginForMonth, {
          email: d.email,
          date_login: d.date_login
        }])
      })
      const filterActivate = await user.filter(d =>  d.activated === true);
      const filterInactivate = await user.filter( d => d.activated === false);

      setUseActivated(filterActivate.length)
      setUseInactivated(filterInactivate.length)
    } catch (error) {
      alert(error)
    }
  }

  const getPost = async() => {
    try {
      const post = await controllerPost.getAllPosts(token)
      const filterActivate = await post.filter(d =>  d.activated === true);
      const filterInactivate = await post.filter( d => d.activated === false);

      setPostActivate(filterActivate.length)
      setPostInactivated(filterInactivate.length)
    } catch (error) {
      alert(error)
    }
  }

  useEffect(() => {
    getUsers()
    getPost()
  }, [])

  if(!useActivated){
    return(
      <Loader active inline='centered'/>
    )
  }

  return (
    <div className='kpi'>
      <div className='kpi__content'>
        {/* Users */}
        <KpiCard title='Usuarios'>
          <Users activated={useActivated} inactivated={useInactivated} />
        </KpiCard>

        {/* Posts */}
        <KpiCard title='Publicaciones'>
          <Post activated={postActivate} inactivated={postInactivated} />
        </KpiCard>

        {/* UserLogin */}
        <KpiCard title='Usuarios logueados' isBar>
          <UserLogin dataLogin={loginForMonth} />
        </KpiCard>

      </div>
    </div>
  )
}
