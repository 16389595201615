import { SERVER_IP } from '../utils'

export class Auth {
  async login(user, password){
    try {
      const url = `${SERVER_IP}/api/user/login`
      const params = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "email" : user,
          "password": password,
        })
      }

      const response = await fetch(url, params)
      const result = await response.json()
      if(response.status !== 200) throw result
      return result

    } catch (error) {
      throw error
    }
  }

  setAccessToken(token) {
    localStorage.setItem('access', token)
  }

  getAccessToken() {
    return localStorage.getItem('access')
  }

  setUserId(user_id){
    localStorage.setItem('user_id', user_id)
  }

  getUserId() {
    return localStorage.getItem('user_id')
  }

  setUserRole(role){
    localStorage.setItem('user_role', role)
  }

  getUserRole() {
    return localStorage.getItem('user_role')
  }
  
  removeToken() {
    localStorage.removeItem('access')
    localStorage.removeItem('user_id')
    localStorage.removeItem('user_role')
  }
}