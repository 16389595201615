import React, { useCallback } from 'react'
import { Form, Image, Checkbox } from 'semantic-ui-react'
import toast, { Toaster } from 'react-hot-toast'
import { useFormik } from 'formik'
import { useDropzone } from 'react-dropzone'
import { initialValues, validationSchema } from './UserForm.form'
import { postUsers, putUsers } from '../../api'
import { SERVER_IP } from '../../utils'
import { Image as imagens } from '../../assets/'
import { useAuth } from '../../hooks'
import './UseForm.scss'

const createUserController = new postUsers()
const updateUserController = new putUsers()

export function UseForm(props) {
  const { token } = useAuth()
  const { close, onReload, user, msgResult, userInactive } = props

  const formik = useFormik({
    initialValues: initialValues(user),
    validationSchema: validationSchema(user),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        if(!user){
          const response = await createUserController.postUser(token, formValue)
          if(response.data){
            onReload()
            userInactive()
            close()
          }
          msgResult('ok', response.msg)
        } else {
          const response = await updateUserController.putUser(token, formValue, user.id_user)
          if(response.msg){
            onReload()
            close()
          }
          msgResult('ok', response.msg)
        }
      } catch (error) {
        if(error.error){
          msgResult('error', error.error) 
        } else {
          msgResult('error', error.msg) 
        }
      }
    }
  })

  const onDrop = useCallback ((acceptedFiles) => {
    const file = acceptedFiles[0]
    formik.setFieldValue('avatar', URL.createObjectURL(file))
    formik.setFieldValue('fileAvatar', file)
  })

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDrop
  })

  const getImage = () => {
    if(formik.values.fileAvatar){
      return formik.values.avatar
    } else if (formik.values.avatar){
      return `${SERVER_IP}/${formik.values.avatar}`
    }
    return imagens.noAvatar
  }
  
  return (
    <>
      <Form className='user-form' onSubmit={formik.handleSubmit}>
        <div className='user-form__avatar' {...getRootProps()}>
          <input {...getInputProps()} />
          <Image avatar size='small' src={getImage()} />
        </div>

        <Form.Group widths='equal'>
          <Form.Input
            maxLength='50'
            name='name'
            placeholder='Nombre'
            onChange={formik.handleChange}
            value={formik.values.name}
            error={formik.errors.name}
          />
          <Form.Input 
            maxLength='50'
            name='lastName' 
            placeholder='Apellido' 
            onChange={formik.handleChange}
            value={formik.values.lastName}
            error={formik.errors.lastName}
          />
        </Form.Group>

        <Form.Group widths='equal'>
          <Form.Input 
            maxLength='50'
            name='email' 
            placeholder='Correo electronico'
            onChange={formik.handleChange}
            value={formik.values.email}
            error={formik.errors.email}
          />
          <Form.Dropdown 
            name='role'
            placeholder='Seleccióna un rol' 
            options={roleOptions}
            selection
            onChange={(_, data) => formik.setFieldValue('role', data.value) }
            value={formik.values.role}
            error={formik.errors.role}
          />
        </Form.Group>

        <Form.Group widths='equal'>
          <Form.Input 
            maxLength='50'
            name='password'
            type='password'
            placeholder='Contraseña'
            onChange={formik.handleChange}
            value={formik.values.password}
            error={formik.errors.password}
          />
          <Form.Input 
            maxLength='20'
            name='telephone'
            placeholder='Teléfono'
            onChange={formik.handleChange}
            value={formik.values.telephone}
            error={formik.errors.telephone}
          />
        </Form.Group>

        <Form.Checkbox 
          className='check'
          label="Aceptación de uso de datos personales"
          checked={formik.values.terms}
          onChange={(_, data) => formik.setFieldValue('terms', data.checked)}
        />

        <Form.Button type='submit' primary fluid loading={formik.isSubmitting}>
          {user ? 'Actualizar Usuario' : 'Crear Usuario'}
        </Form.Button>
      </Form>
    </>
  )
}

const roleOptions = [
  {
    key: 'user',
    text: 'Usuario',
    value: 'user'
  },
  {
    key: 'admin',
    text: 'Administrador',
    value: 'admin'
  },
  {
    key: 'mobile',
    text: 'Móvil',
    value: 'mobile'
  },
]
