import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { ClientLayout } from '../layouts/clientLayout'
import { ConfigPage, HomePage, PostPage, User } from '../pages/web'
import { NotFound } from '../components'

export function WebRouter() {
  const loadLayout = (Layout, Page) => {
    return (
      <Layout>
        <Page />
      </Layout>
    )
  }

  return (
    <Routes>
      <Route path='/home' element={loadLayout(ClientLayout, HomePage)} />
      <Route path='/user' element={loadLayout(ClientLayout, User)} />
      <Route path='/postpage' element={loadLayout(ClientLayout, PostPage)} />
      <Route path='/config' element={loadLayout(ClientLayout, ConfigPage)} />
      <Route path='/*' element={loadLayout(ClientLayout, HomePage)} />
    </Routes>
  )
}
