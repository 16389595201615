import React from 'react'
import { Card, Divider, Icon, Image } from 'semantic-ui-react'
import './KpiCard.scss'

export const KpiCard = (props) => {
  const { title, isBar, children } = props

  return (
    isBar ? (
      <Card className='cardBar'>
        <Card.Content className='card__content'>
          <Card.Header>{title || 'Titulo'}</Card.Header>
          { children }
        </Card.Content>
      </Card>
    ) : (
      <Card className='card'>
        <Card.Content className='card__content'>
          <Card.Header>{title || 'Titulo'}</Card.Header>
          { children }
        </Card.Content>
      </Card>
    )
  )
}
