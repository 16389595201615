import React, { useState } from 'react'
import { Image, Button, Icon, Confirm } from 'semantic-ui-react'
import { BasicModal } from '../Shared'
import { UseForm } from '../UseForm'
import { putUsers } from '../../api/putUser'
import { SERVER_IP } from '../../utils'
import { useAuth } from '../../hooks'
import { Image as img } from '../../assets'
import './UserItem.scss'

const userController = new putUsers()

export function UserItem({user, onReload, msgResult}) {
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [isDelete, setIsDelete] = useState(false);
  const { token, ...data} = useAuth()

  const onOpenCloseModal = () => setShowModal((prevState) => !prevState)
  const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState)
  
  // const validateUserActivate = () => {
  //   if()
    
  //   return false
  // }

  const openUpdateUser = () => {
    setTitleModal(`Actualizar: ${user.email}`)
    onOpenCloseModal()
  }

  const openDesactivateActivateConfim = () => {
    setIsDelete(false)
    setConfirmMessage(
      user.activated ? 
      `Desactivar usuario: ${user.email}` 
      : `Activar usuario: ${user.email}`
      )
    onOpenCloseConfirm()
  }

  const openDeleteConfim = () => {
    setIsDelete(true)
    setConfirmMessage(`Eliminar usuario: ${user.email}`)
    onOpenCloseConfirm()
  }

  const onActivateDesactivate = async () => {
    try {
     const response =  await userController.putUserAoD(token, {
         activated: !user.activated,
         role: user.role
        }, 
        user.id_user
      )
      onReload()
      onOpenCloseConfirm()
      msgResult('ok', response.msg)
    } catch (error) {
      msgResult('error', error.error)
    }
  }

  const onDeleteUser = async () => {
    try {
     const response =  await userController.deleteUser(token, user.id_user)
      onReload()
      onOpenCloseConfirm()
      msgResult('ok', response.msg)
    } catch (error) {
      msgResult('error', error.error)
    }
  }
 
  return (
    <>
      <div className='user-item'>
        <div className='user-item__info'>
          <Image 
            avatar
            src={
              user.avatar ? `${SERVER_IP}/${user.avatar}` : img.noAvatar
            }
          />
          <div>
            <p>
            {`${user.lastName} ${user.name}`}
            </p>
            <p>{user.email}</p>
          </div>
        </div>

        <div>
          <Button icon primary onClick={openUpdateUser}>
            <Icon name='pencil' />
          </Button>
          <Button icon color={user.activated ? 'orange' : 'teal'} onClick={openDesactivateActivateConfim} >
            <Icon name={user.activated ? 'ban' : 'check'} />
          </Button>
          <Button icon color='red' onClick={openDeleteConfim}>
            <Icon name='trash' />
          </Button>
        </div>
      </div>

      <BasicModal show={showModal} close={onOpenCloseModal} title={titleModal} >
        <UseForm 
          close={onOpenCloseModal}
          onReload={onReload}
          user={user}
          msgResult={msgResult}
        />
      </BasicModal>

      <Confirm
        open={showConfirm}
        onCancel={onOpenCloseConfirm}
        onConfirm={isDelete ? onDeleteUser : onActivateDesactivate}
        content={confirmMessage}
        size='mini'
      />
    </>
  )
}
