import React, { useCallback, useEffect, useState } from 'react'
import { Form, Image } from 'semantic-ui-react'
import { useDropzone } from 'react-dropzone'
import { useFormik } from 'formik'
import { initialValues, validationSchema } from './PostForm.form'
import { category, posts, subcategory } from '../../api'
import { SERVER_IP } from '../../utils'
import { useAuth } from '../../hooks'
import './UseForm.scss'

const postController = new posts()
const categoryCotroller = new category()
const subcategoryCotroller = new subcategory()

export function PostForm(props) {
  const { token, user } = useAuth()
  const { close, onReload, post, msgResult } = props
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubCategories] = useState([]);
  const [subcategoriesSelect, setSubcategoriesSelect] = useState([]);
  const [files, setFiles] = useState(null);

  const formik = useFormik({
    initialValues: initialValues(post),
    validationSchema: validationSchema(post),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        if(!post){
          const response = await postController.addNewPost(token, formValue, user.id_user, files)
          if(response.msg){
            onReload()
            close()
          }
          msgResult('ok', response.msg)
        } else {
          const response = await postController.putPosts(token, formValue, user.id_user, post, files)
          if(response.msg){
            onReload()
            close()
          }
          msgResult('ok', response.msg)
        }
      } catch (error) {
        if(error.error){
          msgResult('error', error.error) 
        } else {
          msgResult('error', error.msg) 
        }
      }
    }
  })

  const getCategories = async () => {
    try {
      const response = await categoryCotroller.getAllCategory(token)
      setCategories(response)
    } catch (error) {
      console.log(error);
    }
  }

  const getSubCategories = async () => {
    try {
      const response = await subcategoryCotroller.getAllSubCategory(token)

      setSubCategories(response)
      filterSubCategory(post.category, response)
    } catch (error) {
      console.log(error);
    }
  }

  const onDrop = useCallback ((acceptedFiles) => {
    const file = acceptedFiles[0]
      formik.setFieldValue('image', URL.createObjectURL(file))
      formik.setFieldValue('fileImage', file)

    // if(file.type === 'image/png' || file.type === 'image/jpeg'){
    //   formik.setFieldValue('image', URL.createObjectURL(file))
    //   formik.setFieldValue('fileImage', file)
    // } else {
    //   formik.setFieldValue('file', URL.createObjectURL(file))
    //   formik.setFieldValue('filePost', file)
    // }
  })

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
      // 'application/pdf': [],
      // 'application/vnd.ms-excel': [],
      // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []
    },
    onDrop,
  })

  const addFiles = (e) => {
    setFiles(e)
  }

  const getImage = () => {
    if(formik.values.fileImage){
      return formik.values.image
    } else if (formik.values.image){
      return `${SERVER_IP}/${formik.values.image}`
    }
  }

  const getFile = () => {
    if(formik.values.filePost){
      return formik.values.file
    } else if (formik.values.file){
      return `${SERVER_IP}/${formik.values.file}`
    }
  }

  const filterSubCategory = (d, response) => {
    console.log(subcategories);
    const filter = response ? response.filter(data => data.category === d) : subcategories.filter(data => data.category === d)
    setSubcategoriesSelect(filter)
  }

  useEffect(() => {
    getCategories()
    getSubCategories()
  }, []);

  return (
    <Form className='post-form' onSubmit={formik.handleSubmit}>
        <Form.Dropdown 
          name='category'
          placeholder='Categoría' 
          options={categories}
          selection
          onChange={(_, data) => {
            filterSubCategory(data.value)
            formik.setFieldValue('category', data.value)
          }}
          value={formik.values.category}
          error={formik.errors.category}
        />

        {
          subcategoriesSelect.length > 0 &&
            <Form.Dropdown 
              name='subcategory'
              placeholder='Subcategoría' 
              options={subcategoriesSelect}
              selection
              onChange={(_, data) =>  {
                formik.setFieldValue('subcategory', data.value)
              }}
              value={formik.values.subcategory}
              error={formik.errors.subcategory}
            />
        }

        <Form.Input
          name='title'
          placeholder='Titulo'
          onChange={formik.handleChange}
          value={formik.values.title}
          error={formik.errors.title}
        />
        <Form.TextArea 
          name='description' 
          placeholder='Descripción' 
          onChange={formik.handleChange}
          value={formik.values.description}
          error={formik.errors.description}
        />

        <Form.TextArea 
          name='information' 
          placeholder='Información'
          onChange={formik.handleChange}
          value={formik.values.information}
          error={formik.errors.information}
        />

        <div className='post-form__miniature' {...getRootProps()}>
          <input {...getInputProps()} />
          {
            getImage() ? (
              <Image 
                size='small'
                src={getImage()}
              />
            ) :(
              <div>
                <span>Seleccionar imagen</span>
              </div>
            )
          }
        </div>       

        <Form.Checkbox 
          className='check'
          label="Despliega sección Información"
          checked={formik.values.info_view}
          onChange={(_, data) => formik.setFieldValue('info_view', data.checked)}
        />
        {/* <Checkbox 
          className='check' 
          label='Despliega sección Información' 
          checked={info_view}
          onChange={ () => setInfo_view(!info_view)} 
        /> */}
        
        {/* <div className='post-form__file' {...getRootProps()}> */}
        <div className='post-form__file'>
          {/* <input multiple {...getInputProps()} />
          {
            getFile() ? (
              <Image 
                size='small'
                src={getFile()}
              />
            ) :(
              <div>
                <span>Seleccionar Archivo</span>
              </div>
            )
          } */}
          <input type='file' name='files' multiple onChange={(e) => addFiles(e.target.files)} />
        </div> 
      <Form.Button type='submit' primary fluid loading={formik.isSubmitting}>
        {post ? 'Actualizar Post' : 'Crear Post'}
      </Form.Button>
    </Form>
  )
}
