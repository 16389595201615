import React, { useState } from 'react'
import { Button, Confirm, Icon, Item,  } from 'semantic-ui-react'
import { ConfigForm } from '../ConfigForm'
import { BasicModal } from '../Shared'
import { category } from '../../api'
import { useAuth } from '../../hooks'

const controllerCategory = new category()

export const ListConfig = (props) => {
  const { token } = useAuth()
  const [openNew, setOpenNew] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { action, showModal, onReload, data, msgResult, categories} = props

  const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState)

  const editOpen = () => {
    setIsEdit(true)
    setOpenNew((prevState) => !prevState)
  }

  const deleteCategory = async () => {
    try {
      const response = await controllerCategory.deleteCategory(token, data.id_category)
      onOpenCloseConfirm()
      onReload()
      msgResult('ok', response.msg)
    } catch (error) {
      msgResult('error', error.error)
    }
  }

  return (
    <>
      <Item.Group divided>
        <Item>
          <Item.Content verticalAlign='middle' >
            <h3>{data.text}</h3>         
          </Item.Content>
          <Button icon primary onClick={editOpen}>
            <Icon name='pencil' />
          </Button>

          <Button icon color='red' onClick={onOpenCloseConfirm}>
              <Icon name='trash' />
          </Button>
        </Item>
      </Item.Group>

      {
        isEdit ? (
          <BasicModal close={editOpen} show={openNew}  title='Editar Categoría'>
            <ConfigForm close={editOpen} onReload={onReload} config={data} msgResult={msgResult} categories={categories} />
          </BasicModal>
        ) : (
          <BasicModal close={action} show={showModal}  title='Agregar Categoría'>
            <ConfigForm close={action} onReload={onReload} msgResult={msgResult} categories={categories} />
          </BasicModal>
        )
      }

      <Confirm
        open={showConfirm}
        onCancel={onOpenCloseConfirm}
        onConfirm={deleteCategory}
        content={`¿Desea eliminar la categoría ${data.text}?`}
        size='mini'
      />
    </>
  )
}