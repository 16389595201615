import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export function UserLogin(d) {
  let month = [
    {
      nombre: 'Enero',
      total: 0
    },
    {
      nombre: 'Febrero',
      total: 0
    },
    {
      nombre: 'Marzo',
      total: 0
    },
    {
      nombre: 'Abril',
      total: 0
    },
    {
      nombre: 'Mayo',
      total: 0
    },
    {
      nombre: 'Junio',
      total: 0
    },
    {
      nombre: 'Julio',
      total: 0
    },
    {
      nombre: 'Agosto',
      total: 0
    },
    {
      nombre: 'Septiembre',
      total: 0
    },
    {
      nombre: 'Octubre',
      total: 0
    },
    {
      nombre: 'Noviembre',
      total: 0
    },
    {
      nombre: 'Diciembre',
      total: 0
    },
    {
      nombre: 'Sin Login',
      total: 0
    }
  ]
  d.dataLogin.map(dd => {
    switch (moment(dd.date_login).format('MM')) {
      case 'Invalid date':
        month.map(e => {
          e.nombre === 'Sin Login' && e.total ++
        })
      break
      case '01':
        month.map(e => {
          e.nombre === 'Enero' && e.total ++
        })
        break
      case '02':
        month.map(e => {
          e.nombre === 'Febrero' && e.total ++
        })
        break
      case '03':
        month.map(e => {
          e.nombre === 'Marzo' && e.total ++
        })
        break
      case '04':
        month.map(e => {
          e.nombre === 'Abril' && e.total ++
        })
        break
      case '05':
        month.map(e => {
          e.nombre === 'Mayo' && e.total ++
        })
        break
      case '06':
        month.map(e => {
          e.nombre === 'Junio' && e.total ++
        })
        break
      case '07':
        month.map(e => {
          e.nombre === 'Julio' && e.total ++
        })
        break
      case '08':
        month.map(e => {
          e.nombre === 'Agosto' && e.total ++
        })
        break
      case '09':
        month.map(e => {
          e.nombre === 'Septiembre' && e.total ++
        })
        break
      case '10':
        month.map(e => {
          e.nombre === 'Octubre' && e.total ++
        })
        break
      case '11':
        month.map(e => {
          e.nombre === 'Noviembre' && e.total ++
        })
        break
      case '12':
        month.map(e => {
          e.nombre === 'Diciembre' && e.total ++
        })
        break;
        default:
        break;
    }    
    }
  )

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'Login por mes',
      }
    },
  };
  
  const datas = {
    labels: month.map(d => d.nombre),
    datasets: [
      {
        label: 'Usuarios Login',
        data: month.map(d => d.total),
        backgroundColor: '#1C4C9C',
      },
    ],
  };  
  return <Bar options={options} data={datas} />;
}
