import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export function Post(postData) {

  const data2 = {
    labels: ['Activos', 'Inactivos'],
    datasets: [
      {
        data: [postData.activated, postData.inactivated],
        backgroundColor: [
          '#F2822C',
          '#1C4C9C',
        ],
        borderColor: [
          'yellow',
          'blue',
        ],
        borderWidth: 1,
      },
    ],
  };

  return <Pie data={data2} />;
}
