import { getUser } from '../api'
import { useAuth } from '../hooks'
const userController = new getUser()


export const ValidateToken = async() => {
  const { token, logout } = useAuth()

  if(token){
    try {
      await userController.getAllUser(token)
    } catch (error) {
      alert(error.msg)
      logout()
    }
  }
}
