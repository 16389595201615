import React, { useEffect, useState } from 'react'
import { Form, Image } from 'semantic-ui-react'
import { useFormik } from 'formik'
import { initialValues, validationSchema } from './SubcatetoryForm.form'
import { category, subcategory } from '../../api'
import { useAuth } from '../../hooks'

const categoryController = new subcategory()
const categoriesController = new category()

export function SubcatetoryForm(props) {
  const { token } = useAuth()
  const { close, onReload, config, msgResult } = props
  const [categories, setCategories] = useState([]);
  const [category_name, setCategory_name] = useState('');

  const formik = useFormik({
    initialValues: initialValues(config),
    validationSchema: validationSchema(config),
    validateOnChange: false,
    onSubmit: async (formValue) => {
     try {
      formValue.category_name = category_name
      if(!config){
        const response = await categoryController.addNewSubCategory(token, formValue)
        if(response.msg){
          close()
          onReload()
        }
        msgResult('ok', response.msg)
      } else {
        if(formValue.category_name === ""){
          formValue.category_name = config.category_name
        }
        
        const response = await categoryController.putSubCategory(token, formValue, config.id_subcategory)
        if(response.msg){
          close()
          onReload()
        } 
        msgResult('ok', response.msg)
      }
     } catch (error) {
      if(error.error){
        msgResult('error', error.error) 
      } else {
        msgResult('error', error.msg) 
      }
     }
    }
  })

  const getCategories = async () => {
    try {
      const response = await categoriesController.getAllCategory(token)
      setCategories(response)
    } catch (error) {
      console.log(error);
    }
  }

  const getNameCategories = (data) => {
    const category = categories.find(d => d.value === data)
    setCategory_name(category.text)
  }

  useEffect(() => {
    getCategories()
  }, []);
  
  return (
    <Form className='user-form' onSubmit={formik.handleSubmit}>
        <Form.Dropdown 
          name='category'
          placeholder=' Categoría' 
          options={categories}
          selection
          onChange={(_, data) => {
            getNameCategories(data.value)
            formik.setFieldValue('category', data.value) 
          }}
          value={formik.values.category}
          error={formik.errors.category}
        />
        <Form.Input
          maxLength="4"
          name='key'
          placeholder='Key'
          onChange={formik.handleChange}
          value={formik.values.key}
          error={formik.errors.key}
        />
        <Form.Input 
          maxLength="50"
          name='text' 
          placeholder='Nombre' 
          onChange={formik.handleChange}
          value={formik.values.text}
          error={formik.errors.text}
        />
      <Form.Button type='submit' primary fluid loading={formik.isSubmitting}>
        {config ? 'Actualizar subcategoría' : 'Crear subcategoría'}
      </Form.Button>
    </Form>
  )
}
