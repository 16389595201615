import logoDark from "./png/logoDark.png"
import logoLight from "./png/logoLight.png"
import noAvatar from './jpg/no-avatar.jpg'
import logoNewPng from './png/logo.png'
import dow from './png/dow.png'
import up from './png/up.png'

import { ReactComponent as logo } from './svg/logo.svg'
import { ReactComponent as logoWhite } from './svg/logoWhite.svg'
import { ReactComponent as letras } from './svg/letras.svg'
import { ReactComponent as logoNew } from './svg/dow.svg'

const ImagesSvg = {
  logo,
  logoWhite,
  logoNew,
  letras
}

const Image = {
  logoDark,
  logoLight,
  noAvatar,
  dow,
  up,
  logoNewPng
}

// export {imageSvg, image} 
export {Image, ImagesSvg} 