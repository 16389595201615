import { SERVER_IP } from '../utils'

export class subcategory {
  async getAllSubCategory(token){
    try {
      const url = `${SERVER_IP}/api/subcategory/`
      const params = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'xtkfeda': token
        }
      }

      const response = await fetch(url, params)
      const result = await response.json()
      if(response.status !== 200) throw result
      return result

    } catch (error) {
      throw error
    }
  }

  async addNewSubCategory(token, data){
    try {
      const url = `${SERVER_IP}/api/subcategory/`
      const params = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'xtkfeda': token
        },
        body: JSON.stringify({
          "key": data.key,
          "text": data.text,
          "value": data.key,
          "category": data.category,
          "category_name": data.category_name
        })
      }

      const response = await fetch(url, params)
      const result = await response.json()
      if(response.status !== 200) throw result
      return result

    } catch (error) {
      throw error
    }
  }

  async putSubCategory(token, data, id_subcategory){
    try {
      const url = `${SERVER_IP}/api/subcategory/${id_subcategory}`
      const params = {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'xtkfeda': token
        },
        body: JSON.stringify({
          "key": data.key,
          "text": data.text,
          "value": data.key,
          "category": data.category,
          "category_name": data.category_name
        })
      }

      const response = await fetch(url, params)
      const result = await response.json()
      if(response.status !== 200) throw result
      return result

    } catch (error) {
      throw error
    }
  }

  async deleteSubCategory(token, id_subcategory){
    try {
      const url = `${SERVER_IP}/api/subcategory/${id_subcategory}`
      const params = {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'xtkfeda': token
        }
      }

      const response = await fetch(url, params)
      const result = await response.json()
      if(response.status !== 200) throw result
      return result

    } catch (error) {
      throw error
    }
  }
}