import * as Yup from 'yup'

export function initialValues(config) {
  return{
    key: config?.key || '',
    text: config?.text || '',
    category: config?.category || '',
    category_name: config?.category_name || '',
  }
}

export function validationSchema(config) {
  return Yup.object({
    key: Yup.string()
      .required(true),
    text: Yup.string()
      .required(true),
    category: Yup.string()
      .required(true),
  })
}