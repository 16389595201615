import React, { useEffect, useState, useCallback } from 'react'
import { Form, Image } from 'semantic-ui-react'
import { useFormik } from 'formik'
import { useDropzone } from 'react-dropzone'
import { initialValues, validationSchema } from './ConfigForm.form'
import { Image as imagens } from '../../assets/'
import { category } from '../../api'
import { useAuth } from '../../hooks'
import { SERVER_IP } from '../../utils'

const categoryController = new category()

export function ConfigForm(props) {
  const { token } = useAuth()
  const { close, onReload, config, msgResult, categories } = props
  const [configCategory, setConfigCategory] = useState([]);
  const newCategoryNumber = categories.length + 1

  const getOrderView = async() => {
    let configCategorys = []

    if(!config){
      let newTotal = categories.length

      await categories.map(d => 
          configCategorys.push({
          key: d.order_view,
          text: d.order_view,
          value:d.order_view
        })
        )

        configCategorys.push({
          key: newTotal + 1,
          text: newTotal + 1,
          value: newTotal + 1
        })
      
      setConfigCategory(configCategorys)
    } else {
      categories.map(d => 
        configCategorys.push({
        key: d.order_view,
        text: d.order_view,
        value:d.order_view
      })
      )
  
      setConfigCategory(configCategorys)
    }
  }
  
  const formik = useFormik({
    initialValues: initialValues(config),
    validationSchema: validationSchema(config),
    validateOnChange: false,
    onSubmit: async (formValue) => {
    let validateView = categories.find(d => d.order_view === formValue.order_view)
     try {
      if(!config){
        if(validateView) {
          let existingData = validateView
          let idExistingData = existingData.id_category
          let orderViewExistingData = existingData.order_view
          let newOrderView = newCategoryNumber

          const response = await categoryController.addNewCategoryUpdateOrderView(
            token, 
            formValue,
            idExistingData, 
            orderViewExistingData,
            newOrderView
          )

          if(response.msg){
            close()
            onReload()
          }
          msgResult('ok', response.msg)
        } else {
          const response = await categoryController.addNewCategory(token, formValue)
          if(response.msg){
            close()
            onReload()
          }
          msgResult('ok', response.msg)
        }
        
      } else {
        if(validateView.order_view !== config.order_view) {
          let existingData = validateView
          let idExistingData = existingData.id_category
          let orderViewExistingData = existingData.order_view
          let newOrderView = config.order_view
          let idOrderViewNew = config.id_category
          
          const response = await categoryController.putCategoryUpdateOrderView(
            token,
            formValue,
            config.id_category,
            idExistingData,
            orderViewExistingData,
            newOrderView,
            idOrderViewNew
          )

          if(response.msg){
            close()
            onReload()
          } 
          msgResult('ok', response.msg)
        } else {
          const response = await categoryController.putCategory(token, formValue, config.id_category)
          if(response.msg){
            close()
            onReload()
          } 
          msgResult('ok', response.msg)
        }
      }

     } catch (error) {
      if(error.error){
        msgResult('error', error.error) 
      } else {
        msgResult('error', error.msg) 
      }
     }

    }
  })

  const onDrop = useCallback ((acceptedFiles) => {
    const file = acceptedFiles[0]
    formik.setFieldValue('avatar', URL.createObjectURL(file))
    formik.setFieldValue('fileAvatar', file)
  })

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop
  })

  const getImage = () => {
    if(formik.values.fileAvatar){
      return formik.values.avatar
    } else if (formik.values.avatar){
      return `${SERVER_IP}/${formik.values.avatar}`
    }
    return imagens.logoNewPng
  }

  useEffect(() => {
    getOrderView()
  }, []);
  
  return (
    <Form className='user-form' onSubmit={formik.handleSubmit}>
        <div className='user-form__avatar' {...getRootProps()}>
          <input {...getInputProps()} />
          <Image avatar size='small' src={getImage()} />
        </div>
        <Form.Input
          maxLength="4"
          name='key'
          placeholder='Key'
          onChange={formik.handleChange}
          value={formik.values.key}
          error={formik.errors.key}
          />
        <Form.Input
          maxLength="50"
          name='text' 
          placeholder='Nombre' 
          onChange={formik.handleChange}
          value={formik.values.text}
          error={formik.errors.text}
        />
        <Form.Dropdown 
          name='order_by'
          placeholder='Ordenar por' 
          options={orderBY}
          selection
          onChange={(_, data) => formik.setFieldValue('order_by', data.value) }
          value={formik.values.order_by}
          error={formik.errors.order_by}
        />
        <Form.Dropdown 
          name='order_type'
          placeholder='Tipo de ordenamiento' 
          options={ordenType}
          selection
          onChange={(_, data) => formik.setFieldValue('order_type', data.value) }
          value={formik.values.order_type}
          error={formik.errors.order_type}
        />
        <Form.Dropdown 
          name='order_view'
          placeholder='Ubicación'
          options={configCategory}
          selection
          onChange={(_, data) => formik.setFieldValue('order_view', data.value) }
          value={formik.values.order_view}
          error={formik.errors.order_view}
        />
      <Form.Button type='submit' primary fluid loading={formik.isSubmitting}>
        {config ? 'Actualizar categoría' : 'Crear categoría'}
      </Form.Button>
    </Form>
  )
}

const orderBY = [
  {
    key: 'data',
    text: 'Fecha',
    value: 'fecha'
  },
  {
    key: 'title',
    text: 'Título',
    value: 'titulo'
  },
]

const ordenType = [
  {
    key: 'asc',
    text: 'Ascendente',
    value: 'ascendente'
  },
  {
    key: 'desc',
    text: 'Descendente',
    value: 'descendente'
  },
]