import React, { useState } from 'react'
import { Button, Input, Tab } from 'semantic-ui-react'
import toast, { Toaster } from 'react-hot-toast';
import { BasicModal, UseForm, ListUsers, PostForm, ListForm } from '../../../components'
import { ValidateToken } from '../../../components/ValidateToken';
import './PostPage.scss'

export function PostPage() {
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [searchPost, setSearchPost] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const userInactive = () => setActiveIndex(1)

  ValidateToken()

  const onOpenCloseModal = () => {
    setShowModal(!showModal)
  }

  const onReload = () => {
    setReload((prevState) => !prevState)
  }

  const msgResult = (action, data) => {
    if(action === 'ok'){
      toast.success(data, 
        {
          duration: 3000,
        }
      )
    } else {
      toast.error(data)
    }
  }


  const panes = [
    {
      menuItem: 'Post Activos',
      render: () => (
        <Tab.Pane attached={false}>
          <ListForm isFind={false} postActive={true} reload={reload} onReload={onReload} msgResult={msgResult} />
        </Tab.Pane>
      )
    },
    {
      menuItem: 'Post Inactivos',
      render: () => (
        <Tab.Pane attached={false}>
          <ListForm isFind={false} postActive={false} reload={reload} onReload={onReload} msgResult={msgResult} />
        </Tab.Pane>
      )
    },
    {
      menuItem: 'Buscar Post',
      render: () => (
        <Tab.Pane attached={false}>
          <div className='search'>
            <Input
              className='search__filter'
              name='search'
              placeholder='Filtrar'
              onChange={e => setSearchPost(e.target.value)}
            />         
          </div>

          <ListForm 
            reload={reload} 
            onReload={onReload} 
            msgResult={msgResult} 
            isFind={true}
            searchPost={searchPost}
          />
        </Tab.Pane>
      )
    },
  ]

  return (
    <>
      <div className='user-page'>
        <Button
          className='user-page__add'
          primary
          onClick={onOpenCloseModal}
        >
          Nuevo Post
        </Button>

        {/* <Search /> */}

        <Tab menu={{ secondary: true }} panes={panes} activeIndex={activeIndex} onTabChange={(_, d) => setActiveIndex(d.activeIndex)}/>
      </div>

      <BasicModal 
        show={showModal}
        close={onOpenCloseModal}
        title='Crear nuevo post'
      >
        <PostForm close={onOpenCloseModal} onReload={onReload} msgResult={msgResult} />
      </BasicModal>
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
    </>
  )
}
