import { SERVER_IP } from '../utils'

export class postUsers {
  async postUser(token, {avatar, ...data}){
    try {
      const formData = new FormData()

      Object.keys(data).forEach((key) => {
        formData.append(key, data[key])
      })

      if(data.fileAvatar){
        formData.append('avatar', data.fileAvatar)
      }

      const url = `${SERVER_IP}/api/user/`
      const params = {
        method: 'POST',
        headers: {
          'xtkfeda': token
        },
        body: formData
      }

      const response = await fetch(url, params)
      const result = await response.json()
      if(response.status !== 200) throw result
      return result

    } catch (error) {
      throw error
    }
  }
  // async postUser(token, data){
  //   try {
  //     const url = `${SERVER_IP}/api/user/`
  //     const params = {
  //       method: 'POST',
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json',
  //         'xtkfeda': token
  //       },
  //       body: JSON.stringify({
  //           "name": data.nombre,
  //           "lastName": data.apellido,
  //           "email": data.email,
  //           "password": data.password,
  //           "role": data.role
  //       })
  //     }

  //     const response = await fetch(url, params)
  //     const result = await response.json()
  //     if(response.status !== 200) throw result
  //     return result

  //   } catch (error) {
  //     throw error
  //   }
  // }
}