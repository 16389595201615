import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export function Users(dataUser) {
  const data = {
    labels: ['Activos', 'Inactivos'],
    datasets: [
      {
        data: [dataUser.activated, dataUser.inactivated ],
        backgroundColor: [
          '#F2822C',
          '#1C4C9C',
        ],
        borderColor: [
          'yellow',
          'blue',
        ],
        borderWidth: 1,
      },
    ],
  };

  return <Pie data={data} />;
}
