import { useState, useEffect, createContext } from 'react'
import { Auth } from '../api'

const authController = new Auth()

export const AuthContext = createContext()

export function AuthProvider(props) {
  const { children } = props
  const [user, setUser] = useState(null)
  const [token, setToken] = useState(null)
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const accessToken = authController.getAccessToken()
      const getUser = authController.getUserId()
      const getRole = authController.getUserRole()

      const user = {
        id_user: getUser,
        role: getRole
      }

      await login(accessToken, user)
      setIsLoading(false)
    })()
  }, []);

  const login = async (accessToken, userData) => {
    try {
      setToken(accessToken)
      setUser(userData)
    } catch (error) {
      console.log(error);
    }
  }

  const logout = () => {
    setUser(null)
    setToken(null)
    authController.removeToken()
  }
 
  const data = {
    accessToken: token,
    user,
    login,
    token,
    logout
  }
  
  if(isLoading) return null

  return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>
}