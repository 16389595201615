import * as Yup from 'yup'

export function initialValues(user) {
  return{
    avatar: user?.avatar || '',
    fileAvatar: null,
    name: user?.name || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    password: '',
    role: user?.role ||  '',
    telephone: user?.telephone ||  '',
    terms: user?.terms || false
  }
}

export function validationSchema(user) {
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  return Yup.object({
    name: Yup.string()
      .required(true),
    lastName: Yup.string()
      .required(true),
    email: Yup.string()
      .email('El email no es valido')
      .required(true),
    password: user ?  Yup.string().min(4, 'La contraseña debe tener minimo 4 digitos') : Yup.string().min(4, 'La contraseña debe tener minimo 4 digitos').required(true),
    role: Yup.string()
      .required(true),
      telephone: Yup.string().matches(phoneRegExp, 'Teléfono no valido')
      .required(true)
  })
}