import React, { useEffect, useState } from 'react'
import { Card, Icon, Modal } from 'semantic-ui-react'
import { BasicModal, ConfigForm } from '../../../components'
import { ListConfig } from '../../../components/ListConfig/ListConfig'
import { category, subcategory } from '../../../api'
import { useAuth } from '../../../hooks'
import './ConfigPage.scss'
import toast, { Toaster } from 'react-hot-toast'
import { SubcatetoryForm } from '../../../components/SubcatetoryForm'
import { ListSubCategory } from '../../../components/ListSubCategory/ListSubCategory'
import { ValidateToken } from '../../../components/ValidateToken'

const controllerCattegories = new category()
const controllerSubCattegories = new subcategory()

export const ConfigPage = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalTwo, setOpenModalTwo] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);
  const { token } = useAuth()
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubCategories] = useState([]);
  ValidateToken()

  const  getCategories = async () => {
    try {
      const response = await controllerCattegories.getAllCategory(token)
      setCategories(response)
    } catch (error) {
      console.log(error)
    }
  }

  const  getSubCategories = async () => {
    try {
      const response = await controllerSubCattegories.getAllSubCategory(token)
      setSubCategories(response)
    } catch (error) {
      console.log(error)
    }
  }

  const action = () => {
    setShowModal(!showModal)
  }

  const modalOne = () => {
    setOpenModal((prevState) => !prevState)
  }

  const modalTwo = () => {
    setOpenModalTwo((prevState) => !prevState)
  }

  const onReload = () => {
    setReload(!reload)
  }

  const msgResult = (action, data) => {
    if(action === 'ok'){
      toast.success(data)
    } else {
      toast.error(data)
    }
  }
 
  useEffect(() => {
    getCategories()
    getSubCategories()
  }, [reload]);

  return (
    <div className='configContainer'>
      <div className='cards'>
        {/* Categorias */}
        <Card className='config' onClick={modalOne}>
            <Card.Content className='config__content'>
              <Icon size='large' name='options' />  
              <Card.Content header='Categorías' />
            </Card.Content>
        </Card>

        {/* SubCategorias */}
        <Card className='config' onClick={modalTwo}>
            <Card.Content className='config__content'>
              <Icon size='large' name='options' />  
              <Card.Content header='Subcategorías' />
            </Card.Content>
        </Card>
      </div>

      {/* Categorias */}
      <BasicModal close={modalOne} show={openModal}  title='Categorías' action={action} actionTitle={'Agregar'}>
          {
            categories?.length > 0 ? (
              categories.map((d, k) => (
                  <ListConfig 
                    onReload={onReload}
                    action={action}
                    showModal={showModal}
                    key={d.id_category}
                    keyCategory={d.key}
                    value={d.value}
                    text={d.text}
                    data={d}
                    categories={categories}
                    msgResult={msgResult}
                  />
              ))
            ) : (
            <BasicModal close={action} show={showModal} title='Agregar Categoría'>
              <ConfigForm close={action} onReload={onReload} msgResult={msgResult} />
            </BasicModal>
            )
          }
        </BasicModal>

      {/* SubCategorias */}
      <BasicModal close={modalTwo} show={openModalTwo}   title='Categoría - Subcategoría' action={action} actionTitle={'Agregar'}>
          {
            subcategories?.length > 0 ? (
              subcategories.map((d, k) => (
                  <ListSubCategory
                    onReload={onReload}
                    action={action}
                    showModal={showModal}
                    key={d.id_subcategory}
                    keyCategory={d.key}
                    value={d.value}
                    text={d.text}
                    data={d}
                    msgResult={msgResult}
                  />
              ))
            ) : (
            <BasicModal close={action} show={showModal} title='Agregar Subcategoría'>
              <SubcatetoryForm close={action} onReload={onReload} msgResult={msgResult} />
            </BasicModal>
            )
          }
        </BasicModal>

      <Toaster
        position="top-right"
        reverseOrder={false}
      />
    </div>
  )
}