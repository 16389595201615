import React, { useState } from 'react'
import { Tab } from 'semantic-ui-react'
import { RegisterForm, LoginForm } from '../../../components/Admin';
import { ImagesSvg } from '../../../assets'
import './Auth.scss'

export function Auth() {
  const [activeIndex, setActiveIndex] = useState(0);
  const openLogin = () => setActiveIndex(0)

  const panes = [
    {
      menuItem: 'Entrar',
      render: () => (
        <Tab.Pane>
          <LoginForm />
        </Tab.Pane>
      )
    },
    // {
    //   menuItem: 'Nuevo',
    //   render: () => (
    //     <Tab.Pane>
    //       {<RegisterForm openLogin={openLogin} />}
    //     </Tab.Pane>
    //   )
    // },
  ]

  return (
    <div className='auth'>
      <ImagesSvg.letras className='logo' />
      <Tab 
        panes={panes} 
        className='auth__forms' 
        activeIndex={activeIndex}
        onTabChange={(_, d) => setActiveIndex(d.activeIndex)}
      />
    </div>
  )
}
