import React, { useState } from 'react'
import { Button, Input, Tab } from 'semantic-ui-react'
import toast, { Toaster } from 'react-hot-toast';
import { BasicModal, UseForm, ListUsers } from '../../../components'
import './User.scss'
import { ValidateToken } from '../../../components/ValidateToken';

export function User() {
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [searchUser, setSearchUser] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const userInactive = () => setActiveIndex(1)

  ValidateToken()

  const onOpenCloseModal = () => {
    setShowModal(!showModal)
  }

  const onReload = () => {
    setReload((prevState) => !prevState)
  }

  const msgResult = (action, data) => {
    if(action === 'ok'){
      toast.success(data)
    } else {
      toast.error(data)
    }
  }

  const panes = [
    {
      menuItem: 'Usuarios Activos',
      render: () => (
        <Tab.Pane attached={false}>
          <ListUsers isFind={false} usersActive={true} reload={reload} onReload={onReload} msgResult={msgResult}/>
        </Tab.Pane>
      )
    },
    {
      menuItem: 'Usuarios Inactivos',
      render: () => (
        <Tab.Pane attached={false}>
          <ListUsers isFind={false} usersActive={false} reload={reload} onReload={onReload} msgResult={msgResult} />
        </Tab.Pane>
      )
    },
    {
      menuItem: 'Buscar Usuarios',
      render: () => (
        <Tab.Pane attached={false}>
          <div className='search'>
            <Input  
              className='search__filter'
              name='search'
              placeholder='Filtrar'
              onChange={e => setSearchUser(e.target.value)}
            />         
          </div>

          <ListUsers 
            reload={reload} 
            onReload={onReload} 
            msgResult={msgResult} 
            isFind={true}
            searchUser={searchUser}
          />
        </Tab.Pane>
      )
    },
  ]

  return (
    <>
      <div className='user-page'>
        <Button
          className='user-page__add'
          primary
          onClick={onOpenCloseModal}
        >
          Nuevo usuario
        </Button>

        <Tab menu={{ secondary: true }} panes={panes} activeIndex={activeIndex} onTabChange={(_, d) => setActiveIndex(d.activeIndex)}/>
      </div>

      <BasicModal 
        show={showModal}
        close={onOpenCloseModal}
        title='Crear nuevo usuario'
      >
        <UseForm close={onOpenCloseModal} onReload={onReload} msgResult={msgResult} userInactive={userInactive} />
      </BasicModal>
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
    </>
  )
}
