import React from 'react'

import './MenuPanel.scss'
import { Menu, Icon } from 'semantic-ui-react'
import { Link, useLocation } from 'react-router-dom'
import { useAuth } from '../../hooks'

export function MenuPanel() {
  const { pathname } = useLocation()
  const { user } = useAuth()

  const isCurrentPath = (path) => {
    if(path === pathname) return true
    return false
  }

  const isAdmin = user.role === 'admin' ? true : false

  return (
    <Menu fluid vertical icon text className='menu-panel'>
      <Menu.Item as={Link} to='/home' active={isCurrentPath('/home')}>
        <Icon name='dashboard'/>
          Dashboard
      </Menu.Item>
      {
        isAdmin && (
        <Menu.Item as={Link} to='/user' active={isCurrentPath('/user')}>
          <Icon name='user outline'/>
            Usuarios
        </Menu.Item>

        )
      }
      <Menu.Item as={Link} to='/postpage' active={isCurrentPath('/postpage')}>
        <Icon name='newspaper outline'/>
          Post
      </Menu.Item>
      {
        isAdmin && (
        <Menu.Item as={Link} to='/config' active={isCurrentPath('/config')}>
          <Icon name='configure'/>
            Configuración
        </Menu.Item>
        )
      }
    </Menu>
  )
}
