import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { Auth } from '../pages/admin'
import { NotFound } from '../components'

export function AdminRouter() {
  const loadLayout = (Layout, Page) => {
    return (
      <Layout>
        <Page />
      </Layout>
    )
  }

  return (
    <Routes>
      <Route path='/' element={<Auth />} />
      <Route path='/*' element={<NotFound />} />
    </Routes>
  )
}
