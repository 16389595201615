import React, { useEffect, useState } from 'react'
import { Loader } from 'semantic-ui-react'
import { size } from 'lodash'
import { getUser } from '../../api'
import { useAuth } from '../../hooks'
import { UserItem } from '../UserItem'

const userController = new getUser()

export function ListUsers(props) {
  const { usersActive, reload, onReload, msgResult, searchUser, isFind } = props
  const [users, setUsers] = useState(null)
  const { token } = useAuth()

  useEffect(() => {
    (async () => {
      try {
        if(isFind){
          setUsers(null)

          if(!searchUser){
            const response = await userController.getAllUser(token)
            return setUsers(response)
          }

          const response = await userController.getAllUser(token)
          const filter = response.filter(
            d => {
              let val = d.name.toUpperCase().indexOf(searchUser.toUpperCase()) >= 0
              let val2 = d.lastName.toUpperCase().indexOf(searchUser.toUpperCase()) >= 0
              // val3 = d.information.toUpperCase().indexOf(searchText.toUpperCase()) >= 0
    
              if(val){
                return val
              }
              if(val2){
                return val2
              }
              // if(val3){
              //   return val3
              // }
    
              return false
            }
          );
          setUsers(filter)
        } else {
          setUsers(null)
          const response = await userController.getAllUser(token)
          const filter = response.filter(d => d.activated === usersActive)
          setUsers(filter)
        }
      } catch (error) {
        console.log(error);
      }
    })()
  }, [usersActive, reload, searchUser]);

  if(!users) return <Loader active inline='centered' />
  if(size(users) === 0) return 'No existen registros para mostrar'

  return users.map(user => <UserItem key={user.id_user} user={user} onReload={onReload} msgResult={msgResult} />)
}
