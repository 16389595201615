import React from 'react'
import { Button, Modal } from 'semantic-ui-react'
import './BasicModal.scss'

export function BasicModal(props) {
  const { show, close, title, size, children, actionTitle, action} = props

  return (
    <Modal style={{ marginTop: 50, width: '60%' }} closeIcon open={show} onClose={close}  size={size}>
      {title && <Modal.Header>
        {title}
        {
          actionTitle && (<Button primary  onClick={action}>
            {actionTitle}
          </Button>)
        }
        
      </Modal.Header>}
      <Modal.Content>{children}</Modal.Content>
    </Modal>
  )
}

BasicModal.defaultProps = {
  size: 'tiny'
}
