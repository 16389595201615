import { SERVER_IP } from '../utils'

export class posts {
  async getAllPosts(token){
    try {
      const url = `${SERVER_IP}/api/posts/`
      const params = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'xtkfeda': token
        }
      }

      const response = await fetch(url, params)
      const result = await response.json()
      if(response.status !== 200) throw result
      return result

    } catch (error) {
      throw error
    }
  }

  async addNewPost(token, {image, ...data}, user, files){
    try {
      const formData = new FormData()

      Object.keys(data).forEach((key) => {
        formData.append(key, data[key])
      })

      formData.append('id_user', user)
      files && formData.append('file',files[0])

      if(data.fileImage){
        formData.append('image', data.fileImage)
      }
      
      const url = `${SERVER_IP}/api/posts/`
      const params = {
        method: 'POST',
        headers: {
          'xtkfeda': token
        },
        body: formData
      }
      
      const response = await fetch(url, params)
      const result = await response.json()
      if(response.status !== 200) throw result
      return result

    } catch (error) {
      throw error
    }
  }

  async putPosts(token, data, user, post, files){
    try {
      const formData = new FormData()

      Object.keys(data).forEach((key) => {
        formData.append(key, data[key])
      })

      formData.append('id_user', user)
      files && formData.append('file',files[0])

      if(data.fileImage){
        formData.append('image', data.fileImage)
      }      

      const url = `${SERVER_IP}/api/posts/${post.id_post}`
      const params = {
        method: 'PUT',
        headers: {
          'xtkfeda': token
        },
        body: formData,
      }

      const response = await fetch(url, params)
      const result = await response.json()
      if(response.status !== 200) throw result
      return result

    } catch (error) {
      throw error
    }
  }

  async putPostsAoD(token, data, post){
    try {
      const url = `${SERVER_IP}/api/posts/aod/${post}`
      const params = {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'xtkfeda': token
        },
        body: JSON.stringify({
          "activated": data.activated,
          "id_user": data.id_user
        })
      }

      const response = await fetch(url, params)
      const result = await response.json()
      if(response.status !== 200) throw result
      return result

    } catch (error) {
      throw error
    }
  }

  async deletePost(token, id_post){
    try {
      const url = `${SERVER_IP}/api/posts/${id_post}`
      const params = {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'xtkfeda': token
        }
      }

      const response = await fetch(url, params)
      const result = await response.json()
      if(response.status !== 200) throw result
      return result

    } catch (error) {
      throw error
    }
  }
}