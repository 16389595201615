import { SERVER_IP } from '../utils'

export class category {
  async getAllCategory(token){
    try {
      const url = `${SERVER_IP}/api/category/`
      const params = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'xtkfeda': token
        }
      }

      const response = await fetch(url, params)
      const result = await response.json()
      if(response.status !== 200) throw result
      return result

    } catch (error) {
      throw error
    }
  }

  async addNewCategoryUpdateOrderView(token, {avatar, ...data}, idExistingData, orderViewExistingData, newOrderView){
    try {
      const formData = new FormData()

      if(data.fileAvatar){
        formData.append('avatar', data.fileAvatar)
      }

      formData.append('key', data.key)
      formData.append('text', data.text)
      formData.append('value', data.key)
      formData.append('order_type', data.order_type)
      formData.append('order_by', data.order_by)
      formData.append('order_view', data.order_view)
      formData.append('idExistingData', idExistingData)
      formData.append('orderViewExistingData', orderViewExistingData)
      formData.append('newOrderView', newOrderView)

      const url = `${SERVER_IP}/api/category/uov/`
      const params = {
        method: 'POST',
        headers: {
          'xtkfeda': token
        },
        body: formData
      }

      const response = await fetch(url, params)
      const result = await response.json()
      if(response.status !== 200) throw result
      return result

    } catch (error) {
      throw error
    }
  }

  async addNewCategory(token, {avatar, ...data}){
    try {
      const formData = new FormData()

      if(data.fileAvatar){
        formData.append('avatar', data.fileAvatar)
      }

      formData.append('key', data.key)
      formData.append('text', data.text)
      formData.append('value', data.key)
      formData.append('order_type', data.order_type)
      formData.append('order_by', data.order_by)
      formData.append('order_view', data.order_view)

      const url = `${SERVER_IP}/api/category/`
      const params = {
        method: 'POST',
        headers: {
          'xtkfeda': token
        },
        body: formData
      }

      const response = await fetch(url, params)
      const result = await response.json()
      if(response.status !== 200) throw result
      return result

    } catch (error) {
      throw error
    }
  }

  async putCategoryUpdateOrderView(token, {avatar, ...data}, id_category, idExistingData, orderViewExistingData, newOrderView, idOrderViewNew){
    try {
      const formData = new FormData()

      if(data.fileAvatar){
        formData.append('avatar', data.fileAvatar)
      }

      formData.append('key', data.key)
      formData.append('text', data.text)
      formData.append('value', data.key)
      formData.append('order_type', data.order_type)
      formData.append('order_by', data.order_by)
      formData.append('order_view', data.order_view)
      formData.append('idExistingData', idExistingData)
      formData.append('orderViewExistingData', orderViewExistingData)
      formData.append('newOrderView', newOrderView)
      formData.append('idOrderViewNew', idOrderViewNew)

      const url = `${SERVER_IP}/api/category/uov/${id_category}`
      const params = {
        method: 'PUT',
        headers: {
          'xtkfeda': token
        },
        body: formData
      }

      const response = await fetch(url, params)
      const result = await response.json()
      if(response.status !== 200) throw result
      return result

    } catch (error) {
      throw error
    }
  }

  async putCategory(token, data, id_category){
    const formData = new FormData()

    if(data.fileAvatar){
      formData.append('avatar', data.fileAvatar)
    }

    formData.append('key', data.key)
    formData.append('text', data.text)
    formData.append('value', data.key)
    formData.append('order_type', data.order_type)
    formData.append('order_by', data.order_by)
    formData.append('order_view', data.order_view)

    try {
      const url = `${SERVER_IP}/api/category/${id_category}`
      const params = {
        method: 'PUT',
        headers: {
          'xtkfeda': token
        },
        body: formData
      }

      const response = await fetch(url, params)
      const result = await response.json()
      if(response.status !== 200) throw result
      return result

    } catch (error) {
      throw error
    }
  }

  async deleteCategory(token, id_category){
    try {
      const url = `${SERVER_IP}/api/category/${id_category}`
      const params = {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'xtkfeda': token
        }
      }

      const response = await fetch(url, params)
      const result = await response.json()
      if(response.status !== 200) throw result
      return result

    } catch (error) {
      throw error
    }
  }
}