import React from 'react'
import { ImagesSvg } from '../../assets'
import './ClientLayout.scss'
import { MenuPanel } from '../../components/MenuPanel'
import { Logout } from '../../components/Logout'

export function ClientLayout(props) {
  const { children } = props

  return (
    <div className='client-layout'>
      <div className='client-layout__left'>
        <ImagesSvg.logoWhite className='logo' />
        <MenuPanel />
      </div>

      <div className='client-layout__right'>
        <div className='client-layout__right-header'>
          <Logout />
        </div>
        <div className='client-layout__right-content'>{children}</div>
      </div>
    </div>
  )
}
