import React, { useReducer, useState } from 'react'
import { Button, Icon, Modal } from 'semantic-ui-react'
import { useAuth } from '../../hooks'
import './Logout.scss'


function exampleReducer(state, action) {
  switch (action.type) {
    case 'close':
      return { open: false }
    case 'open':
      return { open: true, size: action.size }
    default:
      throw new Error('Error al cerrar sesión')
  }
}

export function Logout() {
  const { logout } = useAuth()
  const handleLogout = () => {
    logout()
  }

  const [state, dispatch] = useReducer(exampleReducer, {
    open: false,
    size: undefined,
  })
  const { open, size } = state

  return (
    <>
      <Button icon basic onClick={() => dispatch({ type: 'open', size: 'mini' })}>
        <Icon name='power off'/> Salir
      </Button>

      <Modal
        size={size}
        open={open}
        onClose={() => dispatch({ type: 'close' })}
      >
        <Modal.Header>Desea cerrar sesión</Modal.Header>
        <Modal.Actions>
          <Button negative onClick={() => dispatch({ type: 'close' })}>
            No
          </Button>
          <Button positive onClick={() => {
            dispatch({ type: 'close' })
            handleLogout()
          }}>
            Si
          </Button>
        </Modal.Actions>        
      </Modal>
    </>
  )
}
