import * as Yup from 'yup'

export function initialValues(config) {
  return{
    avatar: config?.avatar || '',
    fileAvatar: null,
    key: config?.key || '',
    text: config?.text || '',
    order_by: config?.order_by || '',
    order_type: config?.order_type || '',
    order_view: config?.order_view || ''
  }
}

export function validationSchema(config) {
  return Yup.object({
    key: Yup.string()
      .required(true),
    text: Yup.string()
      .required(true),
    order_by: Yup.string()
      .required(true),
    order_type: Yup.string()
      .required(true),
    order_view: Yup.string()
      .required(true),
  })
}