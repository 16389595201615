import React from 'react'
import { Form } from 'semantic-ui-react'
import { useFormik } from 'formik'
import { initialValues, validationSchema } from './LoginForm.form'
import { Auth } from '../../../api/'
import { useAuth } from '../../../hooks'
import { Navigate } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'

const authController = new Auth();

export function LoginForm() {
   const { login } =  useAuth()

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: validationSchema(),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        const res = await authController.login(
          formValue.email,
          formValue.password,
        );

        if(res.userData.role === 'mobile') return toast.error('¡No tienes privilegios de acceso!')

        authController.setAccessToken(res.datos[0].token)
        authController.setUserId(res.userData.id_user)
        authController.setUserRole(res.userData.role)
        login(res.datos[0].token, res.userData)
      } catch (error) {
        toast.error(error.error)
      }
    }
  })

  return (
    <>
      <Form className='login-form' onSubmit={formik.handleSubmit}>
        <Form.Input 
          name='email'
          placeholder='Correo electronico'
          onChange={formik.handleChange}
          value={formik.values.email}
          error={formik.errors.email}
        />

        <Form.Input 
          name='password'
          type='password'
          placeholder='Contraseña'
          onChange={formik.handleChange}
          value={formik.values.password}
          error={formik.errors.password}
        />

        <Form.Button 
          type='submit'
          primary
          fluid
          loading={formik.isSubmitting}
        >
          Entrar
        </Form.Button>
      </Form>
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
    </>
  )
}
